// Brand Colors
export const background = "#000307" // Primary background color
export const backgroundAlt = "#f7f6f1" // Alternate background color
export const charcoal = "#0f1217"
export const callout = "#ff5a5a"
export const calloutAlt = "#ffc070"
export const calloutTert = "#00ff42"
export const error = "#ff5a5a"
export const link = "#0071ff"
export const type = "#000307"
export const typeTert = "#55606d"
export const foreground = "#FFFFFF"
export const border = "rgba(255,255,255,0.07)"

// Rgb Values
export const calloutRgb = "255, 90, 90"
export const calloutAltRgb = "255, 192, 112"
export const backgroundRgb = "0, 3, 7"
export const foregroundRgb = "255, 255, 255"
export const typeAltRgb = "27, 42, 61"
