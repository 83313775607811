import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import styled from 'styled-components'

// Utilities
import { colors, fonts } from "../utilities"
// import { queryBelow } from "../utilities/mediaQuery"

// Assets
// import logo from  '../assets/images/electric-fun.svg'

// Logo
const MenuHeadingContainer = styled.div`
  border-bottom: 1px solid ${colors.border};
  margin-bottom: 1em;
`

const MenuHeadingTitle = styled.h5`
  color: rgba(${colors.foregroundRgb}, 0.2);
  font-family: ${fonts.fontLight};
  letter-spacing: 0.2em;
  margin-bottom: 0.8em;
  user-select: none;
`

class MenuHeading extends Component {
  render() {
    const { title } = this.props

    return(
      <MenuHeadingContainer>
        <MenuHeadingTitle>
          {title}
        </MenuHeadingTitle>
      </MenuHeadingContainer>
    )
  }
}

export default MenuHeading
