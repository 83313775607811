import React, { Component } from 'react';
import styled, { css } from 'styled-components'
import axios from 'axios'

// Utilities
import { colors, fonts } from "../utilities"
import { queryBelow } from "../utilities/mediaQuery"
import firebase from '../utilities/firebase.js'

// Assets
import efLogo from  '../assets/images/electric-fun-dark.svg'

// Styled Components
const ContactPageContainer = styled.div`
  background: linear-gradient(45deg, ${colors.background}, 67%, ${colors.link});
  display: inline-block;
  min-height: 100vh;
  height: 100%;
  position: relative;
  width: 100%;

  &:after {
    background-image: url(${efLogo});
    background-size: 300%;
    background-position: -120%;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;

    ${queryBelow("bigPhone", css`
      background-position: -90vw -84vw;
    `)}
  }
`

const ContactFormContainer = styled.div`
  background: linear-gradient(45deg, ${colors.backgroundAlt}, 67%, ${colors.foreground});
  box-shadow: 0px 0px 22em -3.2em rgba(${colors.backgroundRgb}, 0.9);
  display: flex;
  padding: 5.4em 7em;
  z-index: 2;
  outline: 2px solid ${colors.foreground};
  outline-offset: -1em;

  ${queryBelow("bigPhone", css`
    padding: 4.4em 4.6em;
  `)}

  ${queryBelow("phone", css`
    font-size: 0.875em;
    outline: none;
    padding: 3.2em 3em;
  `)}
`

const ContactWrap = styled.div`
  align-items: flex-start;
  flex-direction: column;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  overflow: hidden;
  position: relative;
`

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${queryBelow("phone", css`
    flex-direction: column;
  `)}

  ${[InputContainer]} {
    width: 47.5%;

    ${queryBelow("phone", css`
      width: 100%;
    `)}
  }
`

const FormHeader = styled.div`
  margin-bottom: 3rem;
`

const Surheading = styled.h4`
  align-items: center;
  color: ${colors.link};
  display: flex;
  margin-bottom: 1rem;
  position: relative;

  span {
    letter-spacing: 0.2em;
  }
`

const PrimaryHeading = styled.h2`
  margin-bottom: 1rem;
`

const FormHeaderDescription = styled.p`
  font-size: 1em;
`

const SubmitButton = styled.button`
  background-color: ${colors.link};
  color: ${colors.foreground};
  display: flex;
  font-family: ${fonts.fontBold};
  font-size: 1em;
  padding: 1em 2em;
`

// Contact
class Contact extends Component {
  constructor() {
    super();

    this.state = {
      formSuccess: false,
      formError: false,
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      message: "",
    }

    this.inputChange = this.inputChange.bind(this)
    this.contactFormSubmisson = this.contactFormSubmisson.bind(this)
  }

  inputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  contactFormSubmisson(event) {
    event.preventDefault();

    const formComponent = this
    const formSubmission = firebase.database().ref('contactFormSubmissons')
    const submission = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailAddress: this.state.emailAddress,
      phoneNumber: this.state.phoneNumber,
      message: this.state.message,
    }

    formSubmission.push(submission)

    axios.post("https://us-central1-electric-fun.cloudfunctions.net/sendMail", submission).then(function (response) {
      console.log(response);
      formComponent.setState({
        formSuccess: true,
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        message: "",
      })
    }).catch(function (error) {
      console.log(error);
      formComponent.setState({ formError: true })
    });
  }

  render() {
    return (
      <ContactPageContainer id="contact">
        <section>
          <ContactWrap className="small-wrap">
          {
            /*
            <LogoContainer>
              <Logo />
            </LogoContainer>
            */
          }
            <ContactFormContainer className="full-box dark-cursor">

              { this.state.formSuccess ? (
                <FormHeader className="full-box">
                  <Surheading>
                    <span>Success</span>
                  </Surheading>
                  <PrimaryHeading>Thank you!</PrimaryHeading>
                  <FormHeaderDescription>Your message has been sent, and we'll be back with you as soon as we can.</FormHeaderDescription>
                </FormHeader>
              ) : (
                <form onSubmit={this.contactFormSubmisson}>
                  { this.state.formError &&
                    <div>It seems something might not be working, we apologize. Please contact info@electricfun.io directly with your inquiry.</div>
                  }

                  <FormHeader className="full-box">
                    <Surheading>
                      <span>Get in touch</span>
                    </Surheading>
                    <PrimaryHeading>Let's create something incredible.</PrimaryHeading>
                    <FormHeaderDescription>Anything worth doing is worth doing right — and great work doesn't happen by accident. With us, you get precision, purposefulness and the drive to imagine better possibilities.</FormHeaderDescription>
                  </FormHeader>

                  <InputRow>
                    <InputContainer>
                      <label for="firstName">First Name</label>
                      <input
                        id="firstName"
                        onChange={this.inputChange}
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        required
                        value={this.state.firstName}
                      />
                    <span class="underline-animation"></span>
                    </InputContainer>
                    <InputContainer>
                      <label for="lastName">Last Name</label>
                      <input
                        onChange={this.inputChange}
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        required
                        value={this.state.lastName}
                      />
                    <span class="underline-animation"></span>
                    </InputContainer>
                  </InputRow>

                  <InputRow>
                    <InputContainer>
                      <label for="emailAddress">Email Address</label>
                      <input
                        onChange={this.inputChange}
                        type="email"
                        name="emailAddress"
                        id="emailAddress"
                        placeholder="Email Address"
                        required
                        value={this.state.emailAddress}
                      />
                    <span class="underline-animation"></span>
                    </InputContainer>
                    <InputContainer>
                      <label for="phoneNumber">Phone Number</label>
                      <input
                        onChange={this.inputChange}
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="(614) 967-5309"
                        value={this.state.phoneNumber}
                      />
                    <span class="underline-animation"></span>
                    </InputContainer>
                  </InputRow>
                  <InputContainer>
                    <label for="message">Message</label>
                    <textarea
                      onChange={this.inputChange}
                      name="message"
                      id="message"
                      placeholder="How can we help?"
                      value={this.state.message}
                    >
                    </textarea>
                    <span class="underline-animation"></span>
                  </InputContainer>

                  <SubmitButton>Send Message</SubmitButton>
                </form>
              )}

            </ContactFormContainer>
          </ContactWrap>
        </section>
      </ContactPageContainer>
    )
  }
}

export default Contact;
