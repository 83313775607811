import React from 'react';
import styled, {css} from 'styled-components'

// Utilities
import { colors, fonts } from "../utilities"
import { queryBelowHeight } from "../utilities/mediaQuery"

// Styled Components
const TaglineContainer = styled.div`
  bottom: 10em;
  height: 8em;
  left: -5.2em;
  position: absolute;
  width: 1em;
  user-select: none;
  z-index: 1;

  ${queryBelowHeight("500", css`
    display: none;
  `)}
`

const TaglineText = styled.span`
  color: ${colors.foreground};
  display: inline-block;
  font-family: ${fonts.font};
  font-size: 0.55em;
  transform: rotate(90deg);
  min-width: 26em;
  text-transform: uppercase;
  letter-spacing: 0.07em;

  &:last-of-type {
    transform: rotate(-90deg);
  }
`

function Tagline() {
  return (
    <TaglineContainer>
      <TaglineText>Meaningful Experiences.</TaglineText>
      <TaglineText>Crafted. Curated.</TaglineText>
    </TaglineContainer>
  )
}

export default Tagline
