import React, {Component} from 'react'
// import {Link} from 'react-router-dom'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import Value from '../Value/'

// Assets
// import logo from  '../../assets/images/bliss-nova-logo.svg'

// Helpers

// Styled Components
const ValuesContainer = styled.section`
  background-color: ${colors.charcoal};
  padding: 10vh 0;
`

const ValuesList = styled.div`
  margin: 0 auto;
  max-width: 48em;
  position: relative;
  width: 80%;
`

// Logo
class Values extends Component {
  render() {
    return(
      <ValuesContainer id="values">
        <ValuesList>
          <Value
            heading="Our Values"
            content={<h2>Quality and integrity drive every decision we make.</h2>}
          />
          <Value
            heading="Quality"
            content={<p>We do whatever it takes to do things right: we evolve to exceed today’s standards in a way that will deliver and captivate for years to come.</p>}
          />
          <Value
            heading="Respect"
            content={<p>We’re all people — you, us, your audience — and ideal outcomes happen when people with a shared goal treat each other with kindness and respect.</p>}
          />
          <Value
            heading="Integrity"
            content={<p>It’s easy to talk a big game, but only the real ones follow through (goes along with the whole respect thing). That takes accountability, communication and transparency.</p>}
          />
        </ValuesList>
      </ValuesContainer>
    )
  }
}

export default Values
