import React, { Component } from 'react';
import styled, { css } from 'styled-components'

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Components
import Logo from "../Logo"
import MenuHeading from "../MenuHeading"
import PrimaryNav from "../Nav/PrimaryNav"
import SocialNav from "../Nav/SocialNav"

// Styled Components
const Menu = styled.div`
  background-color: rgba(${colors.backgroundRgb}, 0.99);
  left: 0;
  min-height: 100vh;
  padding: 9vh 3em 9vh 8em;
  position: absolute;
  top: 0;
  width: 26em;

  ${queryBelow("phone", css`
    left: 0;
    padding: 9vh 3em;
    width: 100vw;
  `)}
`

const MenuLogoContainer = styled.div`
  margin-bottom: 2em;
`

const NavGroup = styled.nav`
  margin-bottom: 2em;
`
// Menu Drawer
class MenuDrawer extends Component {
  render(props) {
    const { toggleMenuDrawer } = this.props

    return (
      <Menu>
        <MenuLogoContainer>
          <Logo toggleMenuDrawer={toggleMenuDrawer} />
        </MenuLogoContainer>

        <MenuHeading title="Menu" />
        <NavGroup>
          <PrimaryNav toggleMenuDrawer={toggleMenuDrawer} />
        </NavGroup>

        <MenuHeading title="Social" />
        <NavGroup>
          <SocialNav toggleMenuDrawer={toggleMenuDrawer} />
        </NavGroup>
      </Menu>
    )
  }
}

export default MenuDrawer
