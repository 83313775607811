import React from 'react';
import styled from 'styled-components'

// Utilities
// import { colors } from "../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import Hero from '../components/Hero'
import Services from '../components/Services'
import Founders from '../components/Founders'
import Values from '../components/Values/index'
import Contact from '../pages/Contact'

// Styled Components
const HomeContainer = styled.div`
  display: inline-block;
  width: 100%;
`

// Home
function Home() {
  return (
    <HomeContainer>
      <Hero />
      <Services />
      <Founders />
      <Values />
      <Contact />
    </HomeContainer>
  );
}

export default Home;
