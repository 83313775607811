import React, { Component } from 'react';
import styled, { css } from 'styled-components'

// Utilities
import { colors, fonts } from "../../../../utilities"
import { queryBelow } from "../../../../utilities/mediaQuery"

// Components
import ServiceSpecialtyBlock from "../../ServiceSpecialtyBlock"

// Styled Components
const ServicesOverviewHeading = styled.h2`
  color: ${colors.foreground};
  max-width: 16em;
`

const SlideContent = styled.div`
  background-color: ${colors.foreground};
  padding: 8vh 0;
`

const SlideContentWrap = styled.div`
  align-items: baseline;
  justify-content: flex-start;

  ${queryBelow("bigPhone", css`
    flex-wrap: wrap;
  `)}
`

const SlideNote = styled.p`
  color: ${colors.foreground};
  font-family: ${fonts.fontMedium};
  font-size: 0.8em;
  margin: 2em 0 0;
  max-width: 46em;
`

// Digital Slide
class DigitalSlide extends Component {
  render() {
    return (
      <div className="full-box">
        <div className="wrap">
          <div className="full-box">
            <ServicesOverviewHeading>Tell your story on the media of the modern age, from websites to mobile applications and more.</ServicesOverviewHeading>
          </div>
        </div>
        <SlideContent className="full-box dark-cursor">
          <SlideContentWrap className="wrap">
            <ServiceSpecialtyBlock
              title="Web"
              description="We create custom web experiences that connect you with your audience — built in React, Ruby on Rails, or WordPress and based on intuitive, responsive design principles for seamlessness across all devices."
            />
            <ServiceSpecialtyBlock
              title="Apps"
              description="Engage people where they are, and enhance their everyday with a custom mobile app for iOS and Android, designed and developed with best practices of UI/UX."
            />
          </SlideContentWrap>
        </SlideContent>
        <div className="wrap">
          <SlideNote>
            We’re your one stop shop for digital excellence. With state of the art managed hosting solutions, world class marketing services, and proactive care, Fun is the element you didn’t know you were missing.
          </SlideNote>
        </div>
      </div>
    )
  }
}

export default DigitalSlide;
