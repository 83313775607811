import React, {Component} from 'react'
// import {Link} from 'react-router-dom'
import styled from 'styled-components'

// Utilities
import { colors, fonts } from "../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Assets
// import logo from  '../../assets/images/bliss-nova-logo.svg'

// Helpers
let newDate = new Date()
let year = newDate.getFullYear()

// Styled Components
const FooterContainer = styled.footer`
  bottom: 0;
  padding: 8vh 0;
  position: sticky;
  z-index: -1;
`

const FooterWrap = styled.div`
  align-items: center;
`

const Copyright = styled.div`
  color: ${colors.typeTert};
  font-family: ${fonts.fontMedium};
  font-size: 0.6em;
  letter-spacing: 0.2em;
  line-height: 140%;
  text-transform: uppercase;
`

// Logo
class Footer extends Component {
  render() {
    return(
      <FooterContainer>
        <FooterWrap className="wrap">
          <Copyright>&copy; {year} Electric Fun. All Rights Reserved.</Copyright>
        </FooterWrap>
      </FooterContainer>
    )
  }
}

export default Footer
