import React, { Component } from 'react';
import styled, { css } from 'styled-components'

// Utilities
import { colors, fonts } from "../../../../utilities"
import { queryAbove } from "../../../../utilities/mediaQuery"

// Components
import ServiceSpecialtyBlock from "../../ServiceSpecialtyBlock"

// Styled Components
const CreativeSlide = styled.div`
  display: inline-block;
  width: 100%;
`

const ServicesOverviewHeading = styled.h2`
  color: ${colors.foreground};
  max-width: 16em;
`

// const CreativeOverview = styled.p`
//   background-color: ${colors.backgroundAlt};
//   font-family: ${fonts.heading};
//   color: ${colors.background};
//   font-size: 1.1em;
//   max-width: 40em;
//   padding: 2em 2em 2em 8.1%;
//   margin-left:  -8.1%;
// `

const CreativeOverview = styled.p`
  font-family: ${fonts.heading};
  color: ${colors.foreground};
  font-size: 1.1em;
  max-width: 40em;
`

const CreativeSpecialties = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${queryAbove("bigPhone", css`
    margin-bottom: 2em;
  `)}

  > div {
    color: ${colors.foreground};
    margin-bottom: 2em;
    margin-right: 2em;

    p {
      opacity: 0.86;
    }
  }
`

// Home
class ServicesOverviewSlide extends Component {
  render() {
    return (
      <CreativeSlide>
        <div className="wrap">
          <div className="full-box">
            <ServicesOverviewHeading>
              Crafted to tell your story –<br></br>and turn heads.
            </ServicesOverviewHeading>
            <CreativeOverview>
              Creativity is a 24/7 thing for us, not just a 9–5 gig. Our work reflects our values: timeless craftsmanship, captivating user experiences and envelope-pushing strategies. We never settle, and neither should you.
            </CreativeOverview>
            <CreativeSpecialties>
              <ServiceSpecialtyBlock
                title="Branding"
                description="We’ll help you develop a cohesive identity online and offline across all mediums. Marks that show who you are, what you do and why it matters to your audience."
              />
              <ServiceSpecialtyBlock
                title="Design"
                description="Architecting fresh, effective visuals to get your message to cut through is what it’s all about. Let your exceptional design speak for itself."
              />
              <ServiceSpecialtyBlock
                title="Photography"
                description="Images can make or break your brand. Set yourself apart with custom creative photography that embodies equal parts fashion and function."
              />
              <ServiceSpecialtyBlock
                title="Video"
                description="Video is everywhere, but it’s crucial to not add to the noise. Taking cues from cinema and contemporary art, we’ve got the chops to make your audio-visuals shine."
              />
            </CreativeSpecialties>
          </div>
        </div>
      </CreativeSlide>
    )
  }
}

export default ServicesOverviewSlide;
