import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components'

// Utilities
import { colors, fonts } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
// import Logo from "./Logo"

// Styled Components
const PrimaryNavLink = styled(Link)`
  color: ${colors.foreground};
  display: inline-block;
  font-family: ${fonts.headingSerifLight};
  font-size: 2.4em;
  letter-spacing: 0.02em;
  padding: 0.8rem 0;
  position: relative;
  user-select: none;
  width: 100%;

  &:hover {
    color: ${colors.callout};
  }
`

// Primary Nav
class PrimaryNav extends Component {
  render(props) {
    const { toggleMenuDrawer } = this.props

    return (
      <>
        <PrimaryNavLink
          onClick={toggleMenuDrawer}
          smooth
          to="/#services"
        >
          Services
        </PrimaryNavLink>
        <PrimaryNavLink
          onClick={toggleMenuDrawer}
          smooth
          to="/#founders"
        >
          Founders
        </PrimaryNavLink>
        <PrimaryNavLink
          onClick={toggleMenuDrawer}
          smooth
          to="/#values"
        >
          Values
        </PrimaryNavLink>
        <PrimaryNavLink
          onClick={toggleMenuDrawer}
          smooth
          to="/#contact"
        >
          Contact
        </PrimaryNavLink>
      </>
    )
  }
}

export default PrimaryNav
