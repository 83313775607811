import React, { Component } from 'react';
import styled, { css } from 'styled-components'

// Utilities
import { colors, fonts } from "../../../../utilities"
import { queryAbove } from "../../../../utilities/mediaQuery"

// Components
import ServiceSpecialtyBlock from "../../ServiceSpecialtyBlock"

// Styled Components
const AnalogSlideContainer = styled.div`
  display: inline-block;
  width: 100%;
`

const AnalogSlideWrap = styled.div`
  align-items: flex-start;
  flex-direction: column;
`

const AnalogOverview = styled.p`
  font-family: ${fonts.heading};
  color: ${colors.foreground};
  max-width: 40em;
  font-size: 1.1em;

  ${queryAbove("bigPhone", css`
    margin-bottom: 3em;
  `)}
`

const AnalogSlideHeading = styled.h2`
  color: ${colors.foreground};
`

const AnalogSpecialties = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${queryAbove("bigPhone", css`
    margin-bottom: 2em;
  `)}

  > div {
    color: ${colors.foreground};
    margin-bottom: 2em;
    margin-right: 2em;

    p {
      opacity: 0.86;
    }
  }
`

// Home
class AnalogSlide extends Component {
  render() {
    return (
      <AnalogSlideContainer>
        <AnalogSlideWrap className="wrap">
          <AnalogSlideHeading>
            Make a multi-sensory statement.
          </AnalogSlideHeading>
          <AnalogOverview>
            It can be easy to forget there’s more to a brand than digital. We’re just as thrilled to create meaningful experiences IRL — tactile, exhibitions, installations or even just mood-enhancing playlists for your space. You set the limits, we set the bar.
          </AnalogOverview>

          <AnalogSpecialties>
            <ServiceSpecialtyBlock
              title="Print"
              description="One of the most time tested mediums in existence, print captivates, informs, and reminds us to act. We partner with the highest quality printmakers to perfect projects from business cards to large format media, all at incredible price points."
            />
            <ServiceSpecialtyBlock
              title="Packaging"
              description="Outstanding physical products combine the highest quality printing, manufacturing, and eco-friendly awareness. Outstanding packaging commands higher price points and paves the path to wide scale adoption."
            />
            <ServiceSpecialtyBlock
              title="Sound"
              description="A simple sound can set the tone of a room or an overall mood. A sound can communicate succinctly without words, and can reinforce visual and tactile experiences to deliver undeniable results."
            />
            <ServiceSpecialtyBlock
              title="Lighting"
              description="Everything is amplified or obscured by light, and the way we see each facet of an experience determines its effectiveness and meaning. Lighting is everything, and is often most effective when you forget it is there."
            />
          </AnalogSpecialties>
        </AnalogSlideWrap>
      </AnalogSlideContainer>
    )
  }
}

export default AnalogSlide;
