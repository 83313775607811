import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"

// Styled Components
const ValueContainer = styled.div`
  display: inline-block;
  margin-bottom: 2.6em;
  position: relative;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const ValueHeading = styled.div`
  align-items: center;
  color: ${colors.link};
  display: flex;
  margin-bottom: 1.4em;

  &:after {
    background-color: ${colors.link};
    content: "";
    display: inline-block;
    height: 2px;
    position: absolute;
    right: 0;
    width: 80%;
  }
`

const ValueHeadingH4 = styled.h4`
  background-color: ${colors.charcoal};
  display: inline-block;
  padding: 0 1em 0 0;
  position: relative;
  z-index: 1;
`

const ValueContent = styled.div`
  color: ${colors.foreground};
  float: right;
  max-width: 34em;
  text-align: right;
`

// Logo
class Value extends Component {
  render() {
    const { heading, content } = this.props

    return(
      <ValueContainer>
        <ValueHeading>
          <ValueHeadingH4>
            {heading}
          </ValueHeadingH4>
        </ValueHeading>
        <ValueContent>
          {content}
        </ValueContent>
      </ValueContainer>
    )
  }
}

export default Value
