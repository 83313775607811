import React from 'react'

const SVGSprite = () => (
  <svg className="hide">

    <symbol id="circle-check" viewBox="0 0 426 426">
      <path d="M127.7,173.5l-29.1,29.1l93.6,93.6l208-208l-29.1-29.1L192.2,236.9L127.7,173.5z M379.4,213c0,91.5-74.9,166.4-166.4,166.4
      	S46.6,304.5,46.6,213S121.5,46.6,213,46.6c15.6,0,31.2,2.1,45.8,6.2L291,20.6C267.1,10.2,241.1,5,213,5C98.6,5,5,98.6,5,213
      	s93.6,208,208,208s208-93.6,208-208H379.4z"/>
    </symbol>

    <symbol id="bell" viewBox="0 0 34 36">
      <path d="M29,23 L29,12 C29,5.4 23.6,0 17,0 C10.4,0 5,5.4 5,12 L5,23 L0,30 L34,30 L29,23 Z" id="Fill-1"></path>
      <path d="M17,36 C19.21,36 21,34.21 21,32 L13,32 C13,34.21 14.79,36 17,36" id="Fill-3"></path>
    </symbol>

    <symbol id="inverse-triangle" viewBox="0 0 158 43">
      <path d="M79,0C50,0,23,43,0,43h158C135,43,108,0,79,0z"/>
    </symbol>

    <symbol id="electric-fun-bolt" viewBox="0 0 82.23 161.58">
      <path d="M73.16,2.43c3.38-4.46,9.94-2.74,9,4.84-.37,3-11.82,70.25-16.28,96.64-.76,4.46-6.51,7-10.16,1.77v-.05c-5.92-8.28-13.33-5.75-17.09.75-12.9,24-26.88,50-28.06,51.87C6.78,164.05-1,162.39.11,152.39c.32-3,10.59-62.13,15.05-88.52.75-4.47,6.67-9.79,11.34-3.07,5.75,6.72,12.58,4.19,16.13-2C55.53,34.79,71.81,4.15,73.16,2.43Z"/>
    </symbol>

    <symbol id="electric-fun-logo" viewBox="0 0 542.57 253.9">
      <path className="bolt" d="M278.96,184.54c0.63-0.83,1.85-0.51,1.67,0.9c-0.07,0.56-2.2,13.07-3.03,17.98c-0.14,0.83-1.21,1.3-1.89,0.33
      	c0,0,0,0,0-0.01c-1.1-1.54-2.48-1.07-3.18,0.14c-2.4,4.46-5,9.3-5.22,9.65c-0.7,1.08-2.15,0.77-1.94-1.09
      	c0.06-0.56,1.97-11.56,2.8-16.47c0.14-0.83,1.24-1.82,2.11-0.57c1.07,1.25,2.34,0.78,3-0.37
      	C275.68,190.56,278.71,184.86,278.96,184.54z"/>
      <path className="letters" d="M296.54,155.96c6.59,2.16,16.85-0.21,23.16-9.28l0,0c0.61-0.91,1.42-0.86,2-0.19c0,0,0,0,0,0
      	c1.02,1.02,2.02,1.74,2.82,2.18c5.31,2.92,13.35,3.32,19.77-0.07c6.14-3.24,10.96-7.21,15.4-14.8c0,0-0.01,0-0.01,0
      	c0.71-1.22,1.31-2.41,1.88-3.57c0.36-0.74,0.66-2.26-0.65-2.15c-2.01,0.17-5.15,0.47-6.86,2.51c-1.88,2.24-3.68,4.02-5.45,5.29
      	c-2.56,1.83-4.95,2.63-6.68,3.01c-8.65,1.9-16.08-6.5-13.23-18.71c1.69-7.25,4.49-13.1,9.69-18.96c1.99-2.26,5.97-5.33,9.22-5.23
      	c6.63,0.22,0.94,10.59-2.5,14.41c-2.6,2.89,3.29,4.54,7.25,1.83c4.78-3.28,7.49-7.77,7.83-11.77c0.26-3.03-0.53-5.21-2.52-7.13
      	c-2.88-2.79-8.36-4.44-15.19-2.16c-11.8,3.93-19.98,13.63-24.32,27.1c-2.61,8.1-2.62,14.36-1.45,19.05c0.06,0.22,0.19,0.8,0.22,0.93
      	c0.4,1.66,0.3,2.4-0.28,3.32c-2.58,4.13-6.28,6.82-10.92,5.95c-5.81-1.09-6.76-8.04-4.35-14.95c0.59-1.7,6.37-18.76,10.93-31.95
      	c0.71-2.06-0.34-4.35-2.73-4.03c-1.2,0.16-3.49,0.5-4.83,0.89c-6.39,1.88-7.49,8.78-8.47,11.65c-2.96,8.7-5.28,17.7-6.3,21.76
      	c-1.19,3.87-3.09,10.55-4.37,13.89c-2.28,5.95-6.85,6.96-10,5.25c-4.41-2.4-4.22-8.39-3.84-11.05c0.93-6.5,2.89-11.86,9.56-21.77
      	c2.1-3.12,3.05-5.7,1.86-8.12c-1.33-2.71-4.81-3.23-8.17-2.67c-3.3,0.54-8.65,1.42-12.25,2.05c-1.49,0.26-2.15-0.83-1.94-1.53
      	c0.04-0.13,0.09-0.26,0.13-0.39c0.56-1.64,1.03-3.03,1.4-4.07c0.52-1.5-1.53-2.22-3.03-2.21c-7.81,0.07-10.74,6.35-8.62,10.81
      	c0.21,0.53,0.4,0.76,0.15,1.51c-0.27,0.8-0.67,1.88-0.78,2.2c-2.02,5.76-12.04,36.51-12.05,36.54c-2.58,6.96-7.39,9.69-11.9,7.99
      	c-5.87-2.2-5.39-8.29-2.98-15.2c1.01-2.9,9.1-25.7,16.94-47.69c0.46-1.3,1.64-2.3,3.52-2.6c0.35-0.06,38.56-6.78,47.31-8.34
      	c1.42-0.31,4.57-1.47,5.63-4.26c0.5-1.32,0.36-1,0.77-2.31c0.35-1.11-0.34-1.82-1.34-1.65c-13.79,2.51-30.7,5.42-47.94,8.43
      	c-0.25,0.04-0.5,0.09-0.75,0.13c-1.62,0.29-3.04-1-2.55-2.53c0.14-0.44,0.26-0.73,0.42-1.16c5.55-15.6,10-28.12,10.17-28.64
      	c0.46-1.35-0.26-2.08-1.55-1.8c-0.85,0.18-1.64,0.35-2.59,0.72c-3.43,1.32-10.18,2.26-13.86,13.42c-2.42,7.33-2.86,9.98-8.9,28.24
      	c-0.03,0.1-0.06,0.19-0.1,0.29c-0.14,0.34-0.49,0.26-0.61-0.12c-0.89-2.68-4.42-4.23-8.76-3.47c-10.21,1.78-25.34,4.45-28.02,4.92
      	c-6.67,1.17-9.24,5.16-10.24,7.03c-0.59,1.1,0.24,2.1,1.25,1.92c6.48-1.14,40.29-7.07,40.32-7.08c2.41-0.43,4.56,1.28,3.57,4.19
      	c-0.11,0.33-8.55,25.28-12.06,35.09c-7.73,21.61-17.71,27.55-23.05,28.73c-8.65,1.91-16.08-6.5-13.23-18.71
      	c1.69-7.25,4.5-13.1,9.69-18.96c2-2.25,5.97-5.34,9.22-5.23c6.63,0.22,0.94,10.59-2.5,14.41c-2.6,2.89,3.29,4.54,7.25,1.83
      	c4.78-3.28,7.49-7.77,7.83-11.77c0.26-3.03-0.36-5.04-2.52-7.13c-3.47-3.36-8.69-4.17-14.77-2.4
      	c-11.07,3.23-19.75,13.09-24.21,25.83c-0.11,0.32-0.65,1.8-0.77,2.13c-8.08,21.41-18.59,27.18-23.93,28.35
      	c-6.91,1.52-13.04-3.54-13.77-11.88c-0.04-0.52,0.06-1.11,0.86-1.28c7.86-1.76,14.46-4.54,20.25-8.57
      	c12.75-8.87,12.35-19.85,8.92-23.93c-3.05-3.64-8.96-3.9-15.3-2.31c-12.21,3.07-20.4,13.87-24.74,27.33
      	c-2.9,9.02-2.6,15.75-1.01,20.59c0.41,1.42,0.5,2.29-0.19,3.35c-2.36,3.64-6.13,6.12-10.48,5.29c-5.81-1.09-6.76-8.05-4.34-14.95
      	c2.01-5.74,32.4-91.13,32.86-92.48c0.46-1.35-0.45-2.08-1.74-1.81c-0.85,0.18-1.44,0.36-2.4,0.72c-3.43,1.32-12.03,4.28-15.71,15.44
      	c-2.67,8.08-2.62,9.53-10.62,33.37c-2.52,7.51-14.73,43.31-15.2,44.73c-2.14,6.47-2.34,12.71-0.2,16.89
      	c1.73,3.39,4.03,5.19,8.2,6.55c6.42,2.1,16.31-0.09,22.65-8.58c0.05-0.06,0.19-0.26,0.22-0.29c0.6-0.82,1.28-0.75,1.73-0.39
      	c0.03,0.03,0.21,0.18,0.27,0.24c0.86,0.79,1.69,1.37,2.37,1.75c5.31,2.92,13.35,3.32,19.77-0.07c8.35-4.41,12.86-9.16,18.34-19.26
      	c0.04-0.08,0.19-0.35,0.22-0.4c0.54-0.9,1.44-0.77,1.73-0.06c0.02,0.04,0.12,0.35,0.14,0.45c1.67,6.33,5.48,9.76,7.74,11
      	c5.31,2.92,13.35,3.32,19.77-0.07c7.16-3.78,11.14-7.8,15.49-15.26c0.52-0.89,1.56-0.84,1.87-0.18c0.39,0.86,0.83,2.07,1.52,3.2
      	c1.69,2.76,3.94,5.04,7.54,6.21c4.9,1.6,10.31,1.05,15.73-1.85c3.29-1.76,7.36-6.21,9.61-12.83c2.59-7.64,8.4-25.18,12.79-38.28
      	c0.31-0.76,0.73-1.23,1.66-1c0.01,0,0,0,0.01,0c1.63,0.32,3.26,0.42,4.69,0.25c1.32-0.15,2.97-0.43,3.96-0.61
      	c1.45-0.26,2.41,1.41,1.36,2.9c-9.52,13.61-12.33,29.08-8.56,36.93c2.14,4.45,6.77,7.24,11.23,7.08c4.79-0.17,11.38-2.3,15.9-10.31
      	c0.53-0.94,1.5-0.99,2.07-0.11C291.62,153.89,292.95,154.78,296.54,155.96z M129.63,155.02c2.73-11.55,9.45-21.91,17.62-22.04
      	c6.2-0.1,5.57,9.8-0.07,14.59c-4.69,3.99-10.17,6.32-16.95,7.98C129.86,155.64,129.58,155.41,129.63,155.02z M306.53,89.91
      	c-0.9-3.37-1.27-6.97-1.94-10.65c-0.24-1.31,0.04-3.58,2.51-4.02c3.83-0.67,7.71-1.32,7.96-1.36c2.4-0.39,2.99,1.58,2.43,2.67
      	c-0.41,0.8-5.18,9.66-7.98,13.92C308.68,91.73,307.03,91.79,306.53,89.91z M248.77,208.25c1.05,4.24,0.34,10.37-3.33,15.45
      	c-4.35,6.02-14.95,6.26-12.76,3.17c3.28-4.64,2.99-11.79,0.78-16.57c-2.08-4.51-5.53-7.45-9.3-9.37c-5.71-2.9-10.94-4.09-22.63-3.08
      	c-28.22,2.45-56.46,20.49-82.83,36.63c-28.16,17.23-63.9,26.89-91.4,12.26c-16.95-9.02-29.77-28.77-26.88-54.5
      	c1.99-17.77,9.43-32.53,24.4-43.33c6.21-4.47,15.35-9.04,25.36-11.91c1.66-0.38,2.42-1.49,0.7-2.92
      	c-8.42-7.01-10.7-14.28-11.12-20.39C39.1,104.5,43.43,93.3,51.5,86.53c9.73-8.15,21.05-11.72,32.36-9.89
      	c12.26,1.99,20.2,12.51,17.75,25.86c-0.95,5.16-3.48,8.59-6.93,11.77c-6.49,5.99-15.26,2.63-11.83-0.22
      	c8.21-6.82,10.26-23.28-0.88-25.72c-13.31-2.91-23.9,6.44-27.33,12.25c-1.33,2.26-3.96,7.75-4.3,13.14
      	c-0.4,6.31,1.7,10.29,5.43,14.69c2.94,3.47,5.92,4.71,9.39,6.06c1.06,0.41,1.5,1,0.91,2.94c-0.72,2.36-1.31,4.86-3.45,5.22
      	c-24.33,4.03-44.75,25.49-45.93,50.67c-1.07,22.86,8.48,34.69,21.79,41.53c14.88,7.65,43.46,7.05,80.74-14.77
      	c29.92-17.51,62.18-36.41,94.76-34.56c7.16,0.41,16.58,1.58,24.36,7.3C244.28,197.19,247.13,201.66,248.77,208.25z M534.21,78.17
      	c1.82-6.63,2.6-13.35-3.36-17.37c-6.78-4.58-18.49,0.08-22.98,5.68c-0.08,0.1-0.24,0.31-0.4,0.47c-0.97,0.98-2.1,0.77-2.51-0.09
      	c-0.14-0.3-0.23-0.63-0.27-0.75c-0.97-2.73-3.7-3.43-5.13-3.19c-0.47,0.08-4.32,0.8-4.98,0.91c-1.27,0.22-2.09,1.67-1.39,3.13
      	c1.26,2.64,0.91,5.26-0.68,10.44c-0.05,0.17-7.53,23.29-8.32,25.58c-2.63,7.65-6.79,14-11.98,14.59c-4.52,0.52-8.42-3.53-5.89-10.47
      	c0.13-0.35,9.63-29.95,9.73-30.29c2.33-7.51-2.75-9.26-4.89-8.9c-0.47,0.08-5.37,0.98-6.03,1.1c-1.27,0.22-2.09,1.67-1.39,3.13
      	c1.09,2.29,0.95,4.61-0.15,8.57c-0.51,1.63-4.28,13.79-6.16,19.5c-0.42,1.16-3.59,11.36-4.49,13.52c-2.95,7.06-8.1,10.92-13.04,9.45
      	c-4.47-1.33-5.04-6.39-3.22-12.28c1.98-6.4,6.87-20.33,9.19-29.49c1.69-6.65-3.01-8.51-5.06-8.16c-0.47,0.08-4.32,0.8-4.98,0.91
      	c-1.24,0.22-1.85,1.21-1.42,3c0.82,3.44-1.11,6.45-2.49,7.91c-3.3,3.49-9.12,6.61-14.92,7.91c-1.59,0.36-3.16,0.63-4.73,0.83
      	c-1.01,0.13-1.29-0.66-1.07-1.45c0-0.01,0.06-0.19,0.09-0.27c7.92-24.48,16.13-49.7,20.83-63.35c0.36-0.93,0.72-1.18,2.02-1.04
      	c23.93,2.22,40.53,2.28,52.69,0.7c9.64-1.25,18.15-4.63,22.66-8.59c7.97-6.99,6.86-13.74,4.06-18.5c-0.98-1.66-2.15-1.75-2.75-0.1
      	c-0.95,2.63-2.04,5.13-7.21,9.66c-6.02,5.28-17.76,8.19-33.7,8.55c-9.77,0.22-17.45,0.38-34.35-0.65
      	c-23.97-1.47-39.44-0.43-54.32,7.41c-16.65,8.78-27.05,26-22.96,46.68c3.31,16.75,19.13,26.59,35.5,29.46
      	c0.07,0.01,0.22,0.04,0.23,0.04c0.93,0.19,1.12,0.75,0.94,1.41c-0.04,0.14-0.09,0.28-0.14,0.41c-6.46,19.88-11.82,36.35-13.08,39.98
      	c-7.59,21.9-15.56,34.04-23,41.01c-7.02,6.59-17.28,10.95-28.38,9.33c-5.29-0.77-11.93-3.96-14.33-9.15
      	c-2.26-4.89-2.45-12.64,0.91-17.38c2.23-3.15-8.6-2.92-13.06,3.24c-3.75,5.19-4.48,11.47-3.4,15.8c1.67,6.73,4.09,11.11,9.89,15.93
      	c5.01,4.17,12.36,6.48,18.36,7.3c11.35,1.54,22.72-1.92,32.56-8.91c11.61-8.24,21.51-23.7,29.17-45.07
      	c1.38-3.86,8.55-26.16,16.81-51.74c0.31-0.85,0.75-1.4,1.89-1.54c0.25-0.03,0.77-0.1,1.03-0.13c6.47-0.7,12.74-2.48,18.03-5.41
      	c0.1-0.06,0.31-0.17,0.32-0.17c0.85-0.48,1.66,0.17,1.31,1.15c-0.01,0.02-0.08,0.26-0.12,0.37c-1.77,5.07-3.04,8.95-3.17,9.27
      	c-2.6,6.48-3.24,12.71-1.4,16.9c1.49,3.39,4.18,6.04,8.35,7.14c3.09,0.82,8.82,1,14.68-2.73c1.85-1.18,3.6-2.68,5.21-4.66
      	c0.62-0.7,1.41-0.51,1.8-0.03c0.01,0.01,0.01,0,0.02,0.01c2.89,2.74,7.19,3.41,9.71,3.33c3.64-0.11,9.31-2.54,13.59-7.51
      	c0.05-0.06,0.17-0.18,0.19-0.21c0.48-0.56,1.23-0.38,1.14,0.39c-0.06,0.53-0.18,1.04-0.27,1.55c-0.22,1.3,0.16,3.15,2.08,2.81
      	c0.61-0.11,5.19-0.91,6.89-1.21c1.38-0.24,2.33-1.03,2.58-2.53c0.84-5.01,1.43-10.55,6.22-24.63c0.47-1.38,3.69-10.72,5.26-15.3
      	c0,0,0,0.02,0.01,0.02c0.75-2.12,2.84-8.1,7.04-11.05c3.47-2.44,9.13-3.91,11.95,0.99c2.08,3.62-0.35,9.96-1.69,13.63
      	c-1.51,4.18-6.8,20.88-8.11,25.13c-4.17,13.55-3.33,25.28,1.49,31.37c4.97,6.28,10.25,6.87,14.2,7.09
      	c4.2,0.24,10.17-1.29,13.19-5.47c3-4.14,1.8-8.04,0.33-9.59c-1.55-1.64-2.16-0.68-3.01,0.46c-1.6,2.15-5.62,4.34-9.09,4.02
      	c-4.36-0.4-7.27-2.42-8.53-6.86c-1.44-5.09,0.42-13.39,2.23-19.67C524.49,105.14,531.69,87.36,534.21,78.17z M372.02,71.3
      	c-4.34-15.13,4.37-37.8,29.25-43.31c5.94-1.31,11.89-1.59,17.83-1.41c0.58,0.09,0.89,0.63,0.6,1.44c-0.01,0.02-0.01,0.03-0.01,0.05
      	c-4.52,13.55-13,39.61-20.83,63.69c-0.03,0.11-0.11,0.33-0.12,0.35c-0.34,0.98-1.08,1.37-2.36,1.09c-0.03-0.01-0.23-0.05-0.31-0.07
      	C384.39,90.43,375.3,82.76,372.02,71.3z"/>
    </symbol>

  </svg>
)

export default SVGSprite
