import React, { Component } from "react"
import { Motion, spring, presets } from "react-motion"

// Utilities
import { colors } from "../../utilities"

// AnimatedMenuButton
class AnimatedMenuButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuButtonActive: this.props.menuDrawerOpen
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      menuButtonActive: nextProps.menuDrawerOpen
    };
  }

  handleClick() {
    this.setState({
      menuButtonActive: !this.state.menuButtonActive
    })
  }

  render() {
    const style = {
      overflow: 'visible',
      cursor: 'pointer',
      // disable touch highlighting on devices
      WebkitTapHighlightColor: "rgba(0,0,0,0)",
    }

    return (
      <svg
        viewBox="0 0 96 96"
        onClick={this.handleClick.bind(this)}
        style={style}
      >
        <Motion
          style={{
            x: spring(this.state.menuButtonActive ? 1 : 0, presets.noWobble ),
            y: spring(this.state.menuButtonActive ? 0: 1, presets.noWobble ),
          }}
        >
          {({ x, y }) =>
            <g
              id="navicon"
              fill={`${colors.foreground}`}
              stroke={`${colors.foreground}`}
              strokeLinecap="round"
              strokeWidth="5"
             >
              <line
                transform={`translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`}
                x1="7" y1="26" x2="89" y2="26"
               />
              <line
                transform={`translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`}
                x1="7" y1="70" x2="89" y2="70"
               />
              <line
                transform={`translate(${x * -96})`}
                opacity={y}
                x1="7" y1="48" x2="89" y2="48"
               />
            </g>
          }
        </Motion>
      </svg>
    )
  }
}

export default AnimatedMenuButton
