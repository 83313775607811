import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { colors } from "../utilities"
import { queryBelow } from "../utilities/mediaQuery"

// Assets
import jonPoster from  '../assets/images/jon-spencer.jpg'
import joelPoster from  '../assets/images/joel-trzcinski.jpg'
import jonVideo from  '../assets/video/jon-spencer-ef.mp4'
import joelVideo from  '../assets/video/joel-trzcinski-ef.mp4'

const FoundersContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  ${queryBelow("bigPhone", css`
    min-height: 90vh;
  `)}

  ${queryBelow("phone", css`
    min-height: 74vh;
  `)}

  ${queryBelow("smallPhone", css`
    min-height: 68vh;
  `)}

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    mix-blend-mode: hard-light;
    background: linear-gradient(217deg, rgba(${colors.calloutRgb}, 0), rgba(${colors.calloutRgb}, 0) 40.71%),
            linear-gradient(127deg, rgba(${colors.calloutAltRgb}, 0), rgba(${colors.calloutRgb}, 0) 40.71%),
            linear-gradient(336deg, ${colors.link}, rgba(0,0,255,0) 40.71%);
  }
`

const FoundersContent = styled.div`
  background-color: ${colors.link};
  padding: 4em 0;
  margin: 10vw auto 0;
  mix-blend-mode: hard-light;
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: center;
  width: 55%;
  height: 100%;

  ${queryBelow("phone", css`
    font-size: 0.8em;
    margin-top: 18em;
    padding: 3em 0;
    width: 80%;
  `)}

  ${queryBelow("smallPhone", css`
    margin-top: 10em;
    width: 90%;
  `)}

  h4 {
    background-color: ${colors.foreground};
    display: inline-block;
    color: ${colors.link};
    padding: 2px 2px 2px 4px;
    margin-bottom: 0.8rem;
  }

  p {
    color: ${colors.foreground};
    display: inline;
    font-size: 1em;
  }
`

const FoundersContentWrap = styled.div`
  margin: 0 auto;
  max-width: 32em;
  width: 80%;

  ${queryBelow("smallPhone", css`
    width: 90%;
  `)}
`

const FoundersHeadingContainer = styled.div`
  margin-bottom: 0.8rem;
`

const FoundersHeading = styled.h2`
  font-size: 1.875em;
  display: inline;
  color: ${colors.foreground};
`

const FoundersVideos = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
`

const VideoContainer = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 100%;

  &:first-of-type {
    video {
      margin-top: -5%;
    }
  }
`

const FounderVideo = styled.video`
  bottom: 0;
  left: 50%;
  position: absolute;
  right: 0;
  top: 80%;
  transform: translate(-50%, -50%);
  width: 120%;

  ${queryBelow("largeScreen", css`
    left: 0;
    top: 0;
    transform: translate(-15%,-9%);
    width: 150%;
  `)}

  ${queryBelow("tablet", css`
    left: 0;
    top: 0;
    transform: translate(-15%,-10%);
    width: 160%;
  `)}

  ${queryBelow("bigPhone", css`
    left: 0;
    top: 0;
    transform: translate(-12.5%,-5%);
    width: 150%;
  `)}

  ${queryBelow("smallPhone", css`
  transform: translate(-20%,-5%);
  width: 200%;
  `)}
`

class Founders extends Component {
  render() {
    return (
      <FoundersContainer id="founders">
        <FoundersContent>
          <FoundersContentWrap>
            <h4>The Founders</h4>
            <FoundersHeadingContainer>
              <FoundersHeading>
                Electric Fun is the creative partnership of Jon Spencer and Joel Trzcinski.
              </FoundersHeading>
            </FoundersHeadingContainer>
            <p>With years of agency experience, we have the ambition and know-how to indulge our creative fantasies to pull off the coolest projects for likeminded clients. Plus we’ve amassed an incredible talent network to help us bring your craziest ideas to life and see the results you need well into the future.</p>
          </FoundersContentWrap>
        </FoundersContent>

        <FoundersVideos>
          <VideoContainer>
            <FounderVideo autoBuffer playsInline autoPlay preLoad loop muted poster={jonPoster}>
              <source src={jonVideo} type="video/mp4" />
            </FounderVideo>
          </VideoContainer>
          <VideoContainer>
            <FounderVideo autoBuffer playsInline autoPlay preLoad loop muted poster={joelPoster}>
              <source src={joelVideo} type="video/mp4" />
            </FounderVideo>
          </VideoContainer>
        </FoundersVideos>
      </FoundersContainer>
    )
  }
}

export default Founders
