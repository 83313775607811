import React from 'react';
import styled from 'styled-components'

// Utilities
import { colors } from "../utilities"

// Styled Components
const BoltPatternContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 3em;
  position: relative;
  z-index: 1;
`

const Bolt = styled.svg`
  height: 1em;
  width: 1em;

  &, use {
    fill: ${colors.callout};
  }
`

function BoltPattern() {
  return (
    <BoltPatternContainer>
      <Bolt><use xlinkHref="#electric-fun-bolt" /></Bolt>
      <Bolt><use xlinkHref="#electric-fun-bolt" /></Bolt>
      <Bolt><use xlinkHref="#electric-fun-bolt" /></Bolt>
    </BoltPatternContainer>
  )
}

export default BoltPattern
