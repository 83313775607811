import firebase from 'firebase'
const config = {
  apiKey: "AIzaSyBPZznx5BRIXu6y0eHsOlXL-HSZpmnb60Y",
  // authDomain: "fun-food-friends-eeec7.firebaseapp.com",
  databaseURL: "https://electric-fun.firebaseio.com/",
  projectId: "electric-fun",
  // storageBucket: "fun-food-friends-eeec7.appspot.com",
  // messagingSenderId: "144750278413"
};
firebase.initializeApp(config);
export default firebase;
