import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Flickity from 'react-flickity-component'

// Utilities
import { colors, fonts } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Components
import ServicesOverviewSlide from './ServiceSlides/ServicesOverviewSlide'
import CreativeSlide from './ServiceSlides/CreativeSlide'
import DigitalSlide from './ServiceSlides/DigitalSlide'
import AnalogSlide from './ServiceSlides/AnalogSlide'

// Styled Components
// const ServicesContainer = styled.section`
//   background: linear-gradient(45deg, ${colors.link}, ${colors.link}, ${colors.callout}, ${colors.link}, ${colors.calloutAlt}, ${colors.callout});
//   background-size: 800% 800%;
//   display: inline-block;
//   overflow: hidden;
//   animation: gradient 60s normal forwards;
//
//   @keyframes gradient {
//     0% {
//       background-position: 0% 50%;
//     }
//     100% {
//       background-position: 100% 50%;
//     }
//   }
// `

const ServicesContainer = styled.section`
  background: linear-gradient(45deg, ${colors.charcoal}, 40%, ${colors.link});
  overflow: hidden;
`

const SliderNav = styled.nav`
  justify-content: flex-start;
  margin: 0 auto 8vh;
`

const SliderNavLink = styled.a`
  color: ${colors.background};
  cursor: pointer;
  font-family: ${fonts.headingBold};
  font-size: 0.875em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 0.8em 2em 2em;

  ${queryBelow("phone", css`
    font-size: 0.725em;
    letter-spacing: 0.1em;
    padding: 0.7em;
  `)}

  &:after {
    transition: all 0.2s ease-in-out;
    content: "";
    display: inline-block;
    margin: 0 auto -0.3em;
    width: 96%;
    height: 2px;
  }

  &:hover {
    color: ${colors.foreground};
  }

  &.active {
    color: ${colors.foreground};

    &:after {
      background-color: ${colors.foreground};
    }
  }

  &:first-of-type {
    padding-left: 0;
  }
`

const flickityOptions = {
  contain: true,
  initialIndex: 0,
  lazyLoad: true,
  pageDots: false,
  prevNextButtons: false,
}

// Services
class Services extends Component {
  // TODO: Ugly, but technically works.. will come back to this.
  componentDidMount = () => {
    var carouselNav = document.querySelector('.services-carousel-nav');

    this.flkty.on('select', () => {
      var previousSelectedButton = carouselNav.querySelector('.active');
      var selectedButton = carouselNav.children[ this.flkty.selectedIndex ];
      if (previousSelectedButton) {
        previousSelectedButton.classList.remove('active');
      }
      selectedButton.classList.add('active');
    })
  }

  selectSlide = (slideIndex) => {
    this.flkty.select(slideIndex)
  }

  render() {
    return(
      <ServicesContainer id="services">
        <SliderNav className="wrap services-carousel-nav">
          <SliderNavLink onClick={ this.selectSlide.bind(this, 0) }>Overview</SliderNavLink>
          <SliderNavLink onClick={ this.selectSlide.bind(this, 1) }>Creative</SliderNavLink>
          <SliderNavLink onClick={ this.selectSlide.bind(this, 2) }>Digital</SliderNavLink>
          <SliderNavLink onClick={ this.selectSlide.bind(this, 3) }>Analog</SliderNavLink>
        </SliderNav>

        <Flickity
          className={'carousel'}
          elementType={'div'}
          flickityRef={c => this.flkty = c}
          options={flickityOptions}
          disableImagesLoaded={true}
          reloadOnUpdate
          static
        >
          <ServicesOverviewSlide goToSlide={this.selectSlide} />
          <CreativeSlide goToSlide={this.selectSlide} />
          <DigitalSlide goToSlide={this.selectSlide} />
          <AnalogSlide goToSlide={this.selectSlide} />
        </Flickity>
      </ServicesContainer>
    )
  }
}

export default Services
