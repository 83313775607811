import React, { Component } from 'react';
import styled, { css } from 'styled-components'
import { Motion, spring } from "react-motion";

// Utilities
// import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Components
import AnimatedMenuButton from './AnimatedMenuButton'
import BoltPattern from '../../components/BoltPattern'
import MenuDrawer from './MenuDrawer'
import Tagline from '../../components/Tagline'

// Styled Components
const NavBarContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-end;
  left: 0;
  min-height: 100vh;
  min-width: 4em;
  padding: 0 1em 4rem;
  position: fixed;
  top: 0;
  z-index: 9;

  ${queryBelow("phone", css`
    min-height: 0;
    min-width: 0;
    padding: 0;
    position: absolute;
  `)}
`

const NavBarDecor = styled.div`
  ${queryBelow("phone", css`
    display: none;
  `)}
`

const MenuButton = styled.div`
  display: inline-block;
  left: auto;
  position: absolute;
  top: 9.25vh;
  width: 2em;
  z-index: 9;

  ${queryBelow("phone", css`
    left: 85vw;
  `)}
`

const NavMenuDrawerContainer = styled.div`
  left: 0;
  position: absolute;
  top: 0;
`

// NavBar
class NavBar extends Component {
  state = {
    menuDrawerOpen: false
  }

  toggleMenuDrawer = (e) => {
    // e.preventDefault();

    this.setState(state => ({
      menuDrawerOpen: !state.menuDrawerOpen
    }));
  }

  render() {
    return (
      <NavBarContainer>
        <MenuButton onClick={this.toggleMenuDrawer}>
          <AnimatedMenuButton menuDrawerOpen={this.state.menuDrawerOpen} />
        </MenuButton>
        <NavBarDecor>
          <Tagline />
          <BoltPattern />
        </NavBarDecor>
        <Motion
          defaultStyle={{ left: -600, opacity: 0, }}
          style={{
            left: spring(
              this.state.menuDrawerOpen ? 0 : -600, {
                stiffness: 200,
                damping: 33
              }
            ),
            opacity: spring(
              this.state.menuDrawerOpen ? 1 : 0, {
                stiffness: 200,
                damping: 33
              }
            ),
          }}
        >
          { style => (
            <NavMenuDrawerContainer style={{
              transform: `translateX(${style.left}px)`,
              opacity: style.opacity
            }}>
              <MenuDrawer toggleMenuDrawer={this.toggleMenuDrawer} />
            </NavMenuDrawerContainer>
          )}
        </Motion>
      </NavBarContainer>
    );
  }
}

export default NavBar;
