import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// Utilities
// import { colors } from "../utilities"
// import { queryBelow } from "../utilities/mediaQuery"

// Assets
import logo from  '../assets/images/electric-fun.svg'

// Logo
const LogoLink = styled(Link)`
  background-image: url(${logo});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  height: 2.7em;
  width: 8em;
`

class Logo extends Component {
  render() {
    const { toggleMenuDrawer } = this.props

    return(
      <div onClick={toggleMenuDrawer}>
        <LogoLink to="/" title="Electric Fun - Meaningful Experiences. Crafted. Curated." />
      </div>

    )
  }
}

export default Logo
