import React, { Component } from 'react';
import styled, { css } from 'styled-components'

// Utilities
import { colors } from "../../../utilities"
import { queryBelow } from "../../../utilities/mediaQuery"

// Assets
import Arrow from  '../../../assets/images/arrow-circle-right.svg'

// Components

// Styled Components
const ServiceBlockContainer = styled.a`
  background-color: rgba(255,255,255,0.25);
  color: ${colors.foreground};
  display: inline-block;
  margin: 0 1em 1em;
  padding: 1.6em 2.4em;
  width: 30%;

  ${queryBelow("tablet", css`
    font-size: 0.875em;
  `)}

  ${queryBelow("bigPhone", css`
    padding: 1.4em 2em;
  `)}

  ${queryBelow("phone", css`
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  `)}

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    background-color: ${colors.charcoal};
    color: ${colors.foreground};
  }
`

const ServiceBlockHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
`

const ArrowIcon = styled.span`
  background-image: url(${Arrow});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 2em;
  height: 2em;
`

const ServicesList = styled.div``

const Service = styled.div`
  font-size: 1.2em;
  line-height: 1.2;
  margin-bottom: 0.4em;
`

// Service Block
class ServiceBlock extends Component {
  render() {
    const { goToSlide, serviceTitle, services } = this.props

    return (
      <ServiceBlockContainer onClick={goToSlide}>
        <ServiceBlockHeader>
          <h4>{serviceTitle}</h4>
          <ArrowIcon></ArrowIcon>
        </ServiceBlockHeader>
        <ServicesList>
          {
            services.map((service, index) =>
              <Service key={index}>
                {service}
              </Service>
            )
          }
        </ServicesList>
      </ServiceBlockContainer>
    )
  }
}

export default ServiceBlock;
