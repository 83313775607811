import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect
} from 'react-router-dom';
import styled from 'styled-components'
import './App.scss';

// Utilities
// import { colors } from "./utilities"
// import { queryBelow } from "./utilities/mediaQuery"

// Components
import SVGSprite from './components/SVGSprite'
// import AnnouncementBanner from './components/AnnouncementBanner'
import NavBar from './components/Nav/NavBar'
import Footer from './components/Footer'

// Pages
import Home from './pages/Home'
import Contact from './pages/Contact'
import Error404 from './pages/Error404'

// Styled Components
const AppContainer = styled.div``

function App() {

  return (
    <Router>
      <AppContainer className="App">
        <SVGSprite />
        <NavBar />
        <Switch>
          <Route path="/contact" component={Contact} />
          <Route path="/" exact component={Home} />
          <Route component={Error404} />
        </Switch>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
