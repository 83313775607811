import { css } from 'styled-components';

// EXAMPLE
// ${queryBelow("bigPhone", css`
//   width: 100%;
// `)}

// Pixel Breakpoints
export const querySizes = {
  largeScreen: 1366,
  tablet: 1024,
  bigPhone: 840,
  phone: 680,
  smallPhone: 480,
  smallerPhone: 360,
}

export const queryAbove = (breakpoint, providedCss) => css`
  @media screen and (min-width: ${querySizes[breakpoint] + 1}px) {
    ${providedCss}
  }
`

export const queryBelow = (breakpoint, providedCss) => css`
  @media screen and (max-width: ${querySizes[breakpoint]}px) {
    ${providedCss}
  }
`

export const queryBetween = (max, min, providedCss) => css`
  @media screen and (max-width: ${querySizes[max]}px) and (min-width: ${querySizes[min]}px) {
    ${providedCss}
  }
`

// Height Media Queries
export const queryBelowHeight = (breakpoint, providedCss) => css`
  @media screen and (max-height: ${breakpoint}px) {
    ${providedCss}
  }
`
