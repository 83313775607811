import React, { Component } from 'react';
import styled from 'styled-components'

// Utilities
import { colors, fonts } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
// import Logo from "./Logo"

// Styled Components
const MenuSocialItem = styled.a`
  display: inline-block;
  font-family: ${fonts.headingBold};
  font-size: 0.8em;
  letter-spacing: 0.2em;
  opacity: 0.6;
  padding: 0.8rem 0;
  position: relative;
  text-transform: uppercase;
  user-select: none;
  width: 100%;

  &, &:hover {
    color: ${colors.foreground};
  }

  &:hover {
    opacity: 1;
  }
`

// Primary Nav
class SocialNav extends Component {
  render(props) {
    const { toggleMenuDrawer } = this.props

    return (
      <>
        <MenuSocialItem
          href="https://www.instagram.com/electric__fun/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={toggleMenuDrawer}>
          Instagram
        </MenuSocialItem>
      </>
    )
  }
}

export default SocialNav
