import React, { Component } from 'react';
import styled, { css } from 'styled-components'

// Utilities
import { colors } from "../../../../utilities"
import { queryBelow } from "../../../../utilities/mediaQuery"

// Components
import ServiceBlock from "../../ServiceBlock"

// Styled Components
const ServicesOverviewSlideContainer = styled.div`
  width: 100%;
`

const ServicesOverviewSlideWrap = styled.div`
  flex-direction: column;
  align-items: flex-start;
`

const ServicesOverviewHeading = styled.h2`
  color: ${colors.foreground};
  max-width: 16em;
`

const ServiceBlocks = styled.div`
  display: flex;
  width: 100%;

  ${queryBelow("phone", css`
    flex-wrap: wrap;
  `)}
`

// DATA - Service Lists
const creativeServices = [
  "Branding",
  "Design",
  "Videography",
  "Photography",
  "Consulting"
]

const digitalServices = [
  "Websites / PWAs",
  "iOS & Android Apps",
  "Managed Hosting",
  "Marketing",
]

const analogServices = [
  "Print",
  "Packaging",
  "Sound / Playlists",
  "Lighting"
]

// Home
class ServicesOverviewSlide extends Component {
  render() {
    return (
      <ServicesOverviewSlideContainer>
        <ServicesOverviewSlideWrap className="wrap">
          <ServicesOverviewHeading>
            We believe in making a lasting impression, and that calls for equal parts intention and innovation.
          </ServicesOverviewHeading>
          <ServiceBlocks>
            <ServiceBlock
              goToSlide={this.props.goToSlide.bind(this, 1)}
              serviceTitle="Creative"
              services={creativeServices}
            />
            <ServiceBlock
              goToSlide={this.props.goToSlide.bind(this, 2)}
              serviceTitle="Digital"
              services={digitalServices}
            />
            <ServiceBlock
              goToSlide={this.props.goToSlide.bind(this, 3)}
              serviceTitle="Analog"
              services={analogServices}
            />
          </ServiceBlocks>
        </ServicesOverviewSlideWrap>
      </ServicesOverviewSlideContainer>
    )
  }
}

export default ServicesOverviewSlide;
