import React from 'react';
import styled from 'styled-components'

// Utilities
// import { colors } from "../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
// import Hero from '../components/Hero'
// import Services from '../components/Services'
// import Values from '../components/Values/index'

// Styled Components
const PageContainer = styled.div`
  display: inline-block;
  width: 100%;
`

// Error404
function Error404() {
  return (
    <PageContainer>
      404!
    </PageContainer>
  );
}

export default Error404;
