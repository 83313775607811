// Fonts // Should mirror App.scss vars
export const font = "hk_groteskregular"
export const fontLight = "hk_grotesklight"
export const fontItalic = "hk_groteskitalic"
export const fontBold = "hk_groteskbold"
export const fontBoldItalic = "hk_groteskbold_italic"
export const fontMedium = "hk_groteskmedium"
export const fontMediumitalic = "hk_groteskmedium_italic"
export const fontSemibold = "hk_grotesksemibold"
export const fontSemiboldItalic = "hk_grotesksemibold_italic"
export const heading = "GlacialIndifference"
export const headingBold = "GlacialIndifferenceBold"
export const headingSerif = "freighttext_medium"
export const headingSerifLight = "freighttext_light"
export const social = "SSSocialRegular"
