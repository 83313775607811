import React, { Component } from 'react';
import styled, { css } from 'styled-components'

// Utilities
import { fonts } from "../../../utilities"
import { queryBelow } from "../../../utilities/mediaQuery"

// Components
// import ServiceBlock from "../../ServiceBlock"

// Styled Components
const Specialty = styled.div`
  display: inline-block;
  margin-right: 8vh;
  position: relative;
  width: 27.5%;

  ${queryBelow("bigPhone", css`
    margin-bottom: 2em;
    margin-right: 0;
    width: 100%;
  `)}

  &:last-of-type {
    margin-bottom: 0;
  }

  h4 {
    margin-bottom: 1em;
  }

  p {
    font-family: ${fonts.fontMedium};
    font-size: 0.875em;
    margin-bottom: 0;
  }
`

// Digital Slide
class ServiceSpecialtyBlock extends Component {
  render() {
    const { title, description } = this.props

    return (
      <Specialty>
        <h4>{title}</h4>
        <p>{description}</p>
      </Specialty>
    )
  }
}

export default ServiceSpecialtyBlock;
