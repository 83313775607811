import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

// Utilities
import { colors, fonts } from "../utilities"
import { queryBelow } from "../utilities/mediaQuery"

// Components
import Logo from "./Logo"

// Styled Components
const HeroContainer = styled.section`
  background-color: ${colors.charcoal};
  display: flex;
  overflow: hidden;
  padding: 9vh 0;
`

const HeroWrap = styled.div`
  align-items: baseline;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`

const HeroLogoContainer = styled.div`
  display: inline-block;
  margin-bottom: 8vh;
  z-index: 1;
`

const PrimaryHeading = styled.h1`
  margin-bottom: 0;
`

const PreHeading = styled.span`
  color: ${colors.foreground};
  font-family: ${fonts.headingBold};
  font-size: 1.6rem;
  z-index: 1;

  ${queryBelow("bigPhone", css`
    margin-top: 2em;
    font-size: 2.8vmax;
  `)}
`

const PrimaryHeadingContent = styled.span`
  -webkit-text-stroke: 1.25px ${colors.foreground};
  font-family: ${fonts.headingBold};
  color: rgba(255,255,255, 0);
`

// const CircusCircles = styled.div`
//   mix-blend-mode: multiply;
//   background-color: rgba(0,0,0,0);
//   height: 100vh;
//   overflow: hidden;
//   position: absolute;
//   width: 100%;
//   left: 0;
//   top: 0;
// `

class Hero extends Component {
  render() {
    return(
      <>
        <HeroContainer>
          <HeroWrap className="wrap">
            <HeroLogoContainer>
              <Logo />
            </HeroLogoContainer>
            <PrimaryHeading>
              <PreHeading>
                A boutique creative agency
              </PreHeading>
              <PrimaryHeadingContent>
                Crafting and curating <br></br>meaningful experiences.
              </PrimaryHeadingContent>
            </PrimaryHeading>
          </HeroWrap>

          {/*
            <CircusCircles>
              <div className="rotate">
                <div className="circle"></div>
              </div>
            </CircusCircles>
            */
          }
        </HeroContainer>
      </>
    )
  }
}

export default Hero
